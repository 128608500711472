// Here you can add other styles
//Login styles
.login-page {
  h1 {
    color: $textColor;
  }
  .login-btn {
    color: #fff;
    background: $textColor;
  }
}
// dashboard styles
.c-sidebar {
  background: $textColor;
  .c-sidebar-brand {
    background: #fff;
  }
  //.c-sidebar-nav-title{
  //  color: $primaryColor
  //}
  .c-sidebar-nav-link {
    &:hover {
      background: #fff;
      color: $textColor;
      .c-sidebar-nav-icon {
        color: $textColor !important;
      }
    }
  }
  .c-sidebar-nav-dropdown-toggle {
    &:hover {
      color: $textColor !important;
      .c-sidebar-nav-icon {
        color: $textColor !important;
      }
    }
  }
  .c-sidebar-nav-dropdown-items {
    background: #fff;
    color: $textColor !important;
    .c-sidebar-nav-item {
      .c-sidebar-nav-link {
        color: $textColor;
      }
    }
    .c-sidebar-nav-icon {
      color: $textColor !important;
    }
  }
  //.c-sidebar-nav-icon {
  //  color: $textColor !important;
  //}
  .c-active {
    color: $textColor !important;
    background: #fff !important;
    .c-sidebar-nav-icon {
      color: $textColor !important;
    }
  }
}
// card
.card {
  .card-header-title {
    .card-header-action {
      float: right;
      font-size: 16px;
      color: #fff;
      background: $textColor;
      padding: 4px;
    }
    .search-icon {
      padding: 7px;
      margin-right: -1px;
      margin-top: 0px;
      color: #fff;
      background: $textColor;
    }
    input {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $textColor;
      }
    }
  }
  .modal-title {
    span {
      color: $textColor;
    }
  }
  .pagination {
    display: -webkit-inline-flex;
  }
  .pagination-test {
    padding: 5px;
    li {
      border: 1px solid $textColor;
      padding: 5px;
      cursor: pointer;
      a {
        margin: -5px;
        padding: 10px;
        &:focus {
          outline: none;
        }
      }
    }
    .active {
      background-color: $textColor;
      color: #fff;
    }
  }

}


label {
  color: black;
}
// loading and spinner
#background-overlay {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
}
.spinner-border {
  top: 50%;
  left: 50%;
  position: fixed;
  z-index: 9999;
  background: transparent;
}
.success-message {
  padding: 1rem 0.5rem;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}
.google-map {
  position: relative;
  .search-box {
    position: absolute;
    top: 1rem;
    left: 30%;
    background: none;
    border: none;
    z-index: 10;
  }
  .locate {
    position: absolute;
    top: 1rem;
    right: 4rem;
    background: none;
    border: none;
    z-index: 10;
    img {
      width: 30px;
      cursor: pointer;
    }
  }
}
