// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";
.category-product {
  .list-group {
    a {
      i {
        position: absolute;
        right: 10px;
        font-size: 20px;
      }
    }
  }
}
